<template>
  <div class="syssetting">
    <div class="searchw">
      <Form inline :label-width="52">
        <FormItem label="用户名" :label-width="53" style="width: 150px">
          <Input maxlength="32" v-model="posdata.uname" @on-keyup.13="search"></Input>
        </FormItem>
        <FormItem label="实名" :label-width="40" style="width: 138px">
          <Input maxlength="32" v-model="posdata.relname" @on-keyup.13="search"></Input>
        </FormItem>
        <FormItem label="操作详情" :label-width="66" style="width: 266px">
          <Input maxlength="120" v-model="posdata.info" @on-keyup.13="search"></Input>
        </FormItem>
        <FormItem label="操作时间" :label-width="66" style="width: 410px">
          <DatePicker transfer type="datetime" placeholder="起始时间" style="width: 160px" v-model="posdata.start_time"></DatePicker>
          <span style="color: #515a6e;"> ~ </span>
          <DatePicker transfer type="datetime" placeholder="结束时间" style="width: 160px" v-model="posdata.end_time"></DatePicker>
        </FormItem>
        <FormItem label="" :label-width="0">
          <Button type="default" icon="ios-search" @click="search" @keyup.13="search">搜索</Button>
        </FormItem>
      </Form>
    </div>
    <Table size="small" :columns="columns" :data="data.data" :loading="table_loading">
      <template slot-scope="row" slot="uname">{{row.row.uname||'--'}}</template>
      <template slot-scope="row" slot="relname">{{row.row.relname||'--'}}</template>
      <template slot-scope="row" slot="act_ip">
        <span>{{row.row.act_ip}}</span><br/>
        <span>{{row.row.addr}}</span>
      </template>
    </Table>
    <div class="footer-m">
      <div class="fexport-btn">
        <Button icon="md-download" @click="export_excel" :loading="export_loading">导出Excel</Button>
      </div>
      <Page class-name="pagew page-r" :total="data.total" :current="data.current_page" :page-size="data.per_page" :page-size-opts="[15,30,50]" show-sizer show-total @on-change="page" @on-page-size-change="change_pagesize" />
    </div>
  </div>
</template>
<script>
import XLSX from 'xlsx';
export default {
  name: 'SuperLog',
  data(){
    return {
      columns:[
        {title: '用户名',slot: 'uname',minWidth:80,maxWidth:150},
        {title: '实名',slot: 'relname',minWidth:80,maxWidth:150},
        {title: '操作详情',key: 'info',minWidth:200},
        {title: '操作时间',key:'act_time',minWidth:150,maxWidth:200},
        {title: 'IP/网络地址',slot:'act_ip',minWidth:150,maxWidth:300},
      ],
      data:{},
      table_loading:false,
      export_loading:false,
      posdata:{
        uname:'',
        relname:'',
        info:'',
        start_time:'',
        end_time:'',
        page:1,
        page_size:15
      }
    }
  },
  created() {
    this.init();
  },
  methods:{
    init:function (){
      var _this=this;
      this.table_loading=true;
      this.requestApi('/adm/superlog.html',this.posdata).then(function (res){
        _this.table_loading=false;
        if(res.status==1) _this.data=res.data;
      })
    },
    /**
     * 搜索
     */
    search:function (){
      this.posdata.page=1;
      this.init();
    },
    /**
     * 翻页
     */
    page:function (page){
      this.posdata.page=page;
      this.init();
    },
    /**
     * 切换分页条数
     */
    change_pagesize:function (size){
      this.posdata.page_size=size;
      if(this.posdata.page==1) this.init();
    },
    /**
     * 导出Excel
     */
    export_excel:function (){
      let _this=this,title={
        uname:'用户名',
        relname:'实名',
        info:'操作详情',
        act_time:'操作时间',
        act_ip:'IP地址'
      };
      this.export_loading=true;
      this.requestExcel('/adm/export_superlog.html',title,this.posdata).then(function (res){
        _this.export_loading=false;
        if(res.status!=1) return _this.alertError(res.msg);
        const sheet=XLSX.utils.aoa_to_sheet(res.data);
        const book = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(book, sheet, 'Sheet1');
        XLSX.writeFile(book, '操作日志.xlsx');
      })
    }
  }
}
</script>
<style lang="less">
.nouse{}
</style>